$color-background-caja: #f8f8f8;
$color-background: #b4c4bc;
$color-resalto: #a5dd72;

* {
  font-family: "Lato", sans-serif, Arial;
  box-sizing: border-box;
  // color: #3b4c4b;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  // width: 90%;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 30px;
  overflow-y: auto;
  height: auto;
  max-height: 90%;
  width: 90%;
  border-radius: 10px;
  &:focus {
    outline: 0;
  }
}

.formControl {
  min-width: 120;
}

.flexContainer {
  background-color: $color-background-caja;
  color: #000000;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-background-caja;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-background;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-background;
}
