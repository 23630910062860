.container-micronutrimentos{
	.container-inputs{
		display: flex;
		justify-content: space-between;
		.textfield{
			text-align: center;
			width: 100px;
			background: transparent;
			border-color: lightgray;
			border-radius: 5px;
			border-width: 1px;
			border-style: solid;
			padding: 5px;
			div, input{
				text-align: center;
				padding: 5px;
			}
		}
	}
}