.masonry { /* Masonry container */
	column-count: 2;
	column-gap: 1em;
}

.item { /* Masonry bricks or child elements */
	// width: calc(50% - 20px);
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
	background-clip: padding-box;
	display: block; page-break-before: always; 
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  // background: grey;
  margin-bottom: 30px;
}
@media print{
	@page{
		 size: auto;  margin: 1.5cm; 
	}
	// .my-masonry-grid{margin: 1cm}
	.item	{ display: block; page-break-before: auto;page-break-inside: avoid; }
	
}