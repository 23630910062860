
.panelTiempo::-webkit-scrollbar {
	-webkit-appearance: block;
	display:block
}
.panelTiempo::-webkit-scrollbar:horizontal {
	height: 11px;
}
.panelTiempo::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid white;
	background-color: rgba(0, 0, 0, .5);
}
.panelTiempo::-webkit-scrollbar-track { 
	background-color: #fff; 
	border-radius: 8px; 
} 

.container-dieta-opciones {
  .panelTiempo{
    overflow-x: scroll;
    display: flex;
  }
  .columna{
    display: flex;
    flex-direction: column;
		margin-bottom: 10px;
		min-width: 150px;
    .cont-columna-label{
      display: flex;
      align-items: center;
      width: 100%;
      .columna-label{
        
      }
      .cont-buttons{
        display: none;
      }
      &:hover{
        .cont-buttons{
          display: flex;
          align-items: center;
        }
      }
    }
    .textarea-opciones{
      display: flex;
      align-items: flex-start;
      .show-on-hover{
        display: none;
      }
      &:hover{
        .show-on-hover{
          display: flex;
					flex-direction: column;
          align-items: center;
					margin-left: 4px;
        }
      }
    }
  }
  .text-area{
    width: 100% !important;
  }
  .container-add-tiempo-comida{
    margin-bottom: 20px;
  }
}

@media print {
  .container-dieta-opciones{
		.columna{
			min-width: 0px;
		}
    .textarea-opciones{
      display: flex;
      align-items: center;
    }
    .allow-print{
      display: flex !important;
      align-items: center !important;
      height: 100%;
      width: 100% !important;
      .opcion{
        border: 1px solid #bbbbbb;
        border-radius: 5px;
        padding: 10px;
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .o{
        margin: 0 20px;
      }
    }
  }
}