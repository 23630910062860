.container-dieta-equivalentes-smae{
  .totales{
    width: 10%;
    .total{
      display: inline-block;
      width: 50%;
      p{
        font-size: 7pt;
        // text-align: center;
      }
    }
  }
  .container-totales-input{
    display: flex; 
    align-items: center;
    justify-content: flex-end;
    .total-grupo{
      height: 16vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &>div{
        margin: 10px 0;
      }
      &:nth-child(1), &:nth-child(4), &:nth-child(5){
        height: 8vh;
      }
    }
    .total-general{
    }
  }
  .content-table{
    
    input {
      width: 25%;
      padding: 5px;
      text-align: center;
      margin: 5px 0;
    }
    .subcategoria-names{
      width: 40%; 
      height: 16vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        margin: 10px 0;
      }
      &:nth-child(2){
        height: 8vh;
      }
    }
  }
}

.eqInput{
  background: transparent;
  border-color: lightgray;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.results-box{
	-webkit-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
  box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
  width: 750px;
  margin: auto;
  margin-bottom: 10px;
  .container-chart{
    height: 200px;
    background: #F6F6F6;
    padding:30px 60px;
    display: flex;
    .description-chart{
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        font-weight: bold;
        &:nth-child(2){
          color: #E77067;
        }
        &:nth-child(3){
          color: #F7D542;
        }
        &:nth-child(4){
          color: #7AA9F9;
        }
      }  
    }
    .chart{
      width: 30%;
      height: 100%;
    }
  }
}
.dietaEq{
  border-collapse: separate;
  border-spacing: 0 5px;
}
.resultado-eq{
	display:flex;
	flex-direction:column;
	justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;
	.resultado{
		font-weight: bold;
		font-size: 1.1em;
		color: #a5dd72;
		margin-bottom:5px
  }
  p{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}
@media print {
  .container-dieta-equivalentes-smae{
    margin-top: 10px;
    .container-dieta{

    }
    .dietaEq{
      zoom: 1.2;
    }
    .eqInput{
      // margin:2em;
      background: transparent;
      border-color: lightgray;
      border-radius: 5px;
      border-width: 0px;
      border-style: solid;
    }
    .printDiv{
      padding:2cm;
      display: block; 
      page-break-before: auto;
      page-break-inside: avoid;
    }
    @page { size: auto;  margin: 0mm; }
    #content, #page {
      width: 100%; 
      margin: 0cm; 
      float: none;
    }
    .nombre-tiempo{
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    .nombre-alimento{
      border-left: 10px solid transparent;
    }
  }
  
}
.buttons-dietas{
	text-align: right;
}




















// .eqInput{
//   background: transparent;
//   border-color: lightgray;
//   border-radius: 5px;
//   border-width: 1px;
//   border-style: solid;
// }
// .results-box{
// 	-webkit-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
// 	-moz-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
// 	box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);

// }
// .dietaEq{
//   border-collapse: separate;
//   border-spacing: 0 5px;
// }
// .resultado-eq{
// 	display:flex;
// 	flex-direction:column;
// 	justify-content: space-evenly;
// 	align-items: center;
// 	.resultado{
// 		font-weight: bold;
// 		font-size: 1.1em;
// 		color: #a5dd72;
// 		margin-bottom:5px
// 	}
// }
// @media print {
//   .container-dieta-equivalentes{
//     margin-top: 10px;
//     .dietaEq{
//       zoom: 1.2;
//     }
//     .eqInput{
//       // margin:2em;
//       background: transparent;
//       border-color: lightgray;
//       border-radius: 5px;
//       border-width: 0px;
//       border-style: solid;
//     }
//     .printDiv{
//       padding:2cm;
//       display: block; 
//       page-break-before: auto;
//       page-break-inside: avoid;
//     }
//     @page { size: auto;  margin: 0mm; }
//     #content, #page {
//       width: 100%; 
//       margin: 0cm; 
//       float: none;
//     }
//     .nombre-tiempo{
//       border-top: 10px solid transparent;
//       border-bottom: 10px solid transparent;
//     }
//     .nombre-alimento{
//       border-left: 10px solid transparent;
//     }
//   }
  
// }
// .buttons-dietas{
// 	text-align: right;
// }