.container-list-of-added-products-and-recipes{
	
	// .header-actions{
	// 	display: none;
	// }
	// &:hover{
	// 	.header-actions{
	// 		display: flex;
	// 		// align-items: center;
	// 	}
	// }

	.list-item {
		&:hover {
			.remove-action {
				display: flex;
			}
		}
	}
	
	.remove-action {
		display: none;
	}
	
}