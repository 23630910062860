.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: coral;
  --secondary-color: coral;
  --warning-color: coral;
  --error-color: coral;
  --gad-color: coral;
}


.react-calendar-heatmap { height:150px }
.react-calendar-heatmap .color-scale-1 { fill: #d6e685; }
.react-calendar-heatmap .color-scale-2 { fill: #8cc665; }
.react-calendar-heatmap .color-scale-3 { fill: #44a340; }
.react-calendar-heatmap .color-scale-4 { fill: #1e6823; }
.react-calendar-heatmap .asesoria { 
	stroke: red; /* Outline for important events */
  stroke-width: 2; 
}
.react-calendar-heatmap .asesoria_g { 
	stroke: orange; /* Outline for important events */
  stroke-width: 2; 
}
.react-calendar-heatmap .contratacion { 
	stroke: blue; /* Outline for important events */
  stroke-width: 2; 
}
.react-calendar-heatmap .registro { 
	stroke: yellow; /* Outline for important events */
  stroke-width: 2; 
}