.container-configuraciones{
  display: flex;
  input{
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    display: block;
    width: 100%;
  }
}