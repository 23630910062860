.user-cell{
  display: flex;
  align-items: center;
  .image{
    margin-right: 5px;
  }
  .datos{
		text-align: left;
		margin-left: 10px;
    .nombre{
      font-weight: bold;
      // font-size: 1.5vh;
    }
    .email{
      // font-size: 1.3vh;
    }
  }
}