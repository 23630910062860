.panelTiempo::-webkit-scrollbar {
	-webkit-appearance: none;
	display:block

}
.panelTiempo::-webkit-scrollbar:horizontal {
	height: 11px;
}
.panelTiempo::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid white;
	background-color: rgba(0, 0, 0, .5);
}
.panelTiempo::-webkit-scrollbar-track { 
	background-color: #fff; 
	border-radius: 8px; 
} 

.container-dieta-columnas {
  .more-options{
    display: flex;
  }
  .more-options-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-area{
    // height: 100px;
    border-radius: 5px;
    width: 150px; 
    max-height: 150px;
    padding:5px;
    // max-height: 50px; 
    max-width: 150px;
  }
  .elements-columna{
    display: flex;
    align-items: center;
  }
  
  .panelTiempo{
    overflow-x: scroll;
    display: flex;
  }
  .container-add-tiempo-comida{
    margin-bottom: 20px;
  }
}
.panelTiempo{
  -webkit-box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
  -moz-box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
  box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
  width:100%
} 
.columna{
  margin:5px;
}
@media print {
  .container-dieta-columnas{
    .panelTiempo{
      display: flex;
      box-shadow: unset;
      border: 2px solid #eee;
      border-radius: 5px;
      padding: 5px;
    }
    // .columna{
    //   display: flex;
    //   align-items: flex-start;
    // }
    .allow-print{
      display: block;
      .columna-print{
        border: 1px solid #bbb;
        border-radius: 5px;
        padding: 10px;
      }
      .o{
        margin: 0 20px;
      }
    }
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}