.container-header-print{
	.left, .center, .right{
		padding: 10px;
		width: 33%;
		display: flex;
		flex-direction: column;
		.titulo{
			margin-top: 10px;
			font-size: 18px;
		}
		p:not(.titulo){
			margin-top: 5px;
		}
	}
	display: flex;
	justify-content: space-between;
	.titulo{
		font-weight: bold;
		font-size: 16px;
	}
	.text-center{
		text-align: center;
	}
	.right{
		align-items: flex-end;
		// img{
		// 	height: 100%;
		// 	max-height: 200px;
		// 	max-width: 100%;
		// }
	}
}
@media print {
	.container-header-print{
		.allow-print{
			display: block;
		}
	}
}