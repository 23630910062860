.container-text-area {
  padding: 0 15px;
}
.content-table {
	width: 100%;
	.row-table{
		display: flex;
		justify-content: space-around;
		text-align: center;
	}
  .img-category {
		height: 35px;
  }
  td {
		text-align: center;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
    margin: 0;
  }
  input {
		width: 50%;
    padding: 5px;
    text-align: center;
  }
	.text-area {
		min-width: 100%;
		min-height: 100%;
		// max-height: 100%;
		max-width: 100%;
  }
  .text-field{
    padding: 2px;
    input{

      width: 100%;
    }
  }
  .container-add-tiempo-comida{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      min-width: 20px;
      min-height: 20px;
      padding: 0;
    }
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
// ::-webkit-scrollbar {
//   display: none;
// }
.dietaMenuDia{
  -webkit-box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
  -moz-box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
  box-shadow: -5px 6px 5px -2px rgba(224,224,224,1);
}
@media screen and (max-width: 768px){
  .container-dieta{
    .MuiFormGroup-root{
      width: 100%;
    }
  }
}

.container-dieta-menus{
  .cont-alimento{
    align-items: center; 
    padding: 10px;
    width: 20%;
    .show-on-hover{
      display: none;
    }
    &:hover{
      .show-on-hover{
        display: block;
        text-align: right;
      }
    }
  }
}

.allow-print{
  display: none;
}
.avoid-print{
  display: inline-block;
}
@media print {
  .container-dieta-menus{
    .allow-print{
      display: flex;
      align-items: center;
      .columna-print{
        border: 1px solid #bbb;
        border-radius: 5px;
        padding: 10px;
      .o{
        margin: 0 20px;
        }
      }
    }
    .container-nombre-tiempo{
      margin: 10px 0;
      .nombre-tiempo{
        font-size: 16pt;
      }
    }
  }
}

// .table{
// 	display: table;
// 	.table-header{
// 		display: table-header-group;
// 	}
// 	.table-body{
// 		display: table-row-group;
// 	}
// 	.table-row{
// 		display: table-row;
// 	}
// 	.table-cell, .table-head { 
// 		display: table-cell; 
// 	}
// }
// marginBottom:"10px", alignItems:"center"