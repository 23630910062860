$color-background-caja: #f8f8f8;

.image-tab {
  width: 50px;
}
.reintentar-button{
	display: inline;
	background: transparent;
	border: 0;
	color: darkred;
	padding: 10px;
}