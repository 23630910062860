.eqInput{
  background: transparent;
  border-color: lightgray;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.results-box{
	-webkit-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
	box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);

}
.dietaEq{
  border-collapse: separate;
  border-spacing: 0 5px;
}
.resultado-eq{
	display:flex;
	flex-direction:column;
	justify-content: space-evenly;
	align-items: center;
	.resultado{
		font-weight: bold;
		font-size: 1.1em;
		color: #a5dd72;
		margin-bottom:5px
	}
}
@media print {
  .container-dieta-equivalentes{
    margin-top: 10px;
    .container-dieta{

    }
    .dietaEq{
      zoom: 1.2;
    }
    .eqInput{
      // margin:2em;
      background: transparent;
      border-color: lightgray;
      border-radius: 5px;
      border-width: 0px;
      border-style: solid;
    }
    .printDiv{
      padding:2cm;
      display: block; 
      page-break-before: auto;
      page-break-inside: avoid;
    }
    @page { size: auto;  margin: 0mm; }
    #content, #page {
      width: 100%; 
      margin: 0cm; 
      float: none;
    }
    .nombre-tiempo{
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    .nombre-alimento{
      border-left: 10px solid transparent;
    }
  }
  
}
.buttons-dietas{
	text-align: right;
}




















// .eqInput{
//   background: transparent;
//   border-color: lightgray;
//   border-radius: 5px;
//   border-width: 1px;
//   border-style: solid;
// }
// .results-box{
// 	-webkit-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
// 	-moz-box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);
// 	box-shadow: -1px 1px 4px -1px rgba(0,0,0,0.75);

// }
// .dietaEq{
//   border-collapse: separate;
//   border-spacing: 0 5px;
// }
// .resultado-eq{
// 	display:flex;
// 	flex-direction:column;
// 	justify-content: space-evenly;
// 	align-items: center;
// 	.resultado{
// 		font-weight: bold;
// 		font-size: 1.1em;
// 		color: #a5dd72;
// 		margin-bottom:5px
// 	}
// }
// @media print {
//   .container-dieta-equivalentes{
//     margin-top: 10px;
//     .dietaEq{
//       zoom: 1.2;
//     }
//     .eqInput{
//       // margin:2em;
//       background: transparent;
//       border-color: lightgray;
//       border-radius: 5px;
//       border-width: 0px;
//       border-style: solid;
//     }
//     .printDiv{
//       padding:2cm;
//       display: block; 
//       page-break-before: auto;
//       page-break-inside: avoid;
//     }
//     @page { size: auto;  margin: 0mm; }
//     #content, #page {
//       width: 100%; 
//       margin: 0cm; 
//       float: none;
//     }
//     .nombre-tiempo{
//       border-top: 10px solid transparent;
//       border-bottom: 10px solid transparent;
//     }
//     .nombre-alimento{
//       border-left: 10px solid transparent;
//     }
//   }
  
// }
// .buttons-dietas{
// 	text-align: right;
// }