.container-equivalencias{
	.container-button-micronutrimentos{
		display: flex;
		justify-content: flex-end;
		button{
			background: #3b4c4b;
			color: #f8f8f8;
			margin: 5px;
		}
	}
	.container-table-sticky{
		div{
			overflow-x: unset !important;
		}
	}
}