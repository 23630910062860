@media print{
  .container-dieta{
    zoom: .6;
    .break-end-page{ 
      // display: table-row !important; 
      page-break-inside: avoid;
      page-break-before: auto;
    }
    .panelTiempo{
      display: flex;
      box-shadow: unset;
      border: 2px solid #eee;
      border-radius: 5px;
      padding: 5px;
			overflow-y: unset;
    }
    
    .avoid-print{
      display: none;
    }
    .allow-print{
      display: block;
    }
    .MuiPaper-root{
      background: none !important;
    }
    .MuiPaper-outlined{
      border: none !important;
    }
    .MuiTypography-h5{
      display: none;
    }
    .MuiCard-root{
      overflow: unset;
    }
    .MuiPaper-elevation1{
      box-shadow: none;
    }
  }
  @page {
    size: auto;
    margin: 20cm ;
  }
  .print-text{
    line-height: 1.5;
	}
}


// .avoid-print{
//   display: none;
// }