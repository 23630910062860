.container-text-area {
  padding: 0 15px;
}
.cont-dieta-menu-select{
  .cont-menu{
    // max-height: 600px;
    // position: relative;
    // overflow: scroll;
    // ::-webkit-scrollbar{
    //   display: block !important;
    // }
  }
  input[type="checkbox"]{
    display: none;
  }
  .content-table {
    width: 100%;
    .row-table{
      display: flex;
      justify-content: space-around;
      text-align: center;
    }
    .img-category {
      height: 35px;
    }
    td {
      text-align: center;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      width: 100%;
      padding: 5px;
      text-align: center;
    }
    .text-area {
      min-width: 100%;
      min-height: 100%;
      // max-height: 100%;
      max-width: 100%;
    }
    .text-field{
      padding: 2px;
      input{
  
        width: 100%;
      }
    }
    // .container-add-tiempo-comida{
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   button{
    //     min-width: 20px;
    //     min-height: 20px;
    //     padding: 0;
    //   }
    // }
  }
  // .avoid-print{
  //   display: inline-block;
  // }
	input, textarea {
		border: none;
		outline: none;
		border-bottom: 0; /* You can adjust the color and thickness of the outline */
	}

  @media print {
		.bg-primary-EEF2FD{
			background: var(--primary-color, #EEF2FD) !important;
		}
		.avoid-print{
			display: none !important;
		}
    .cont-dieta-menu-select{
    
      .allow-print{
        display: flex;
        align-items: center;
        .columna-print{
          border: 1px solid #bbb;
          border-radius: 5px;
          padding: 10px;
        .o{
          margin: 0 20px;
          }
        }
      }
      .container-nombre-tiempo{
        margin: 10px 0;
        .nombre-tiempo{
          font-size: 16pt;
        }
      }
    }
  }
  
  // .table{
  // 	display: table;
  // 	.table-header{
  // 		display: table-header-group;
  // 	}
  // 	.table-body{
  // 		display: table-row-group;
  // 	}
  // 	.table-row{
  // 		display: table-row;
  // 	}
  // 	.table-cell, .table-head { 
  // 		display: table-cell; 
  // 	}
  // }
  // marginBottom:"10px", alignItems:"center"
}